import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "authenticator-instruction-2" }
const _hoisted_2 = { class: "font-medium" }
const _hoisted_3 = {
  key: 2,
  class: "field-info px-4 py-2 mb-2"
}
const _hoisted_4 = { class: "field-info px-4 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_QRCode = _resolveComponent("QRCode")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_title, {
      level: 4,
      class: "font-normal mb-4"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 2. " + _toDisplayString(_ctx.t("Scan QR Code")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, { class: "small mb-2" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("With Authenticator app please scan the QR code")), 1)
      ]),
      _: 1
    }),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: "",
          avatar: { size: 80, shape: 'square' },
          paragraph: false,
          title: false
        }))
      : _createCommentVNode("", true),
    (_ctx.otpSetupDetails.uri)
      ? (_openBlock(), _createBlock(_component_QRCode, {
          key: 1,
          value: _ctx.otpSetupDetails.uri
        }, null, 8, ["value"]))
      : _createCommentVNode("", true),
    _createVNode(_component_i18n_t, {
      keypath: "Or add {merchantBrand} manually by typing username and key.",
      tag: "div",
      class: "ant-typography small mt-4"
    }, {
      merchantBrand: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(`${_ctx.config.merchantBrand} profile`), 1)
      ]),
      _: 1
    }),
    (_ctx.loading || _ctx.otpSetupDetails.username)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_a_typography_paragraph, { class: "small m-0 lighter" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Username")), 1)
            ]),
            _: 1
          }),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_a_skeleton, {
                key: 0,
                active: "",
                paragraph: false,
                class: "field-info__text-loading"
              }))
            : (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                key: 1,
                class: "small m-0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.otpSetupDetails.username), 1)
                ]),
                _: 1
              }))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_a_typography_paragraph, { class: "small m-0 lighter" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Secret Key")), 1)
        ]),
        _: 1
      }),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: "",
            paragraph: false,
            class: "field-info__text-loading"
          }))
        : _createCommentVNode("", true),
      (_ctx.otpSetupDetails.secret)
        ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
            key: 1,
            class: "small m-0"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.otpSetupDetails.secret), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}