
import { defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import CommonCardLayout from "../Layouts/CommonCardLayout.vue";
import AuthenticatorInstruction1 from "./AuthenticatorInstruction1.vue";
import AuthenticatorInstruction2 from "./AuthenticatorInstruction2.vue";
import AuthenticatorInstruction3 from "./AuthenticatorInstruction3.vue";
import { config } from "@/shared/utils/config";
import { use2FASetup } from "@/shared/composables/use2FASetup";

export default defineComponent({
  props: {
    // if true, will generate 2fa on mount
    generate2fa: {
      type: Boolean,
      default: false,
    },
    revoke: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["finish"],
  setup(props, { emit }) {
    const otpInputRef = ref();
    const { t } = useI18n();

    const {
      validated,
      otpSetupDetails,
      handleGenerate2fa,
      generate2faLoading,
      handle2faValidate,
      setup2faLoading,
    } = use2FASetup(otpInputRef);

    onMounted(() => {
      if (props.generate2fa) {
        handleGenerate2fa();
      }
    });

    watch(validated, (isValidated) => {
      if (isValidated) {
        emit("finish");
      }
    });

    const handleCodeSubmit = async (otpCode) => {
      const response2faValidate = await handle2faValidate(otpCode);
      console.log({ response2faValidate });
      if (response2faValidate) {
        emit("finish");
      }
    };

    return {
      t,
      otpInputRef,
      config,
      generate2faLoading,
      setup2faLoading,
      handleCodeSubmit,
      otpSetupDetails,
    };
  },
  components: {
    CommonCardLayout,
    AuthenticatorInstruction1,
    AuthenticatorInstruction2,
    AuthenticatorInstruction3,
  },
});
