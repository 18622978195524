import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QRCodeVue3 = _resolveComponent("QRCodeVue3")!

  return (_openBlock(), _createBlock(_component_QRCodeVue3, {
    myclass: "qr-code",
    imgclass: "qr-code-img",
    value: _ctx.value,
    dotsOptions: {
      type: 'square',
      color: _ctx.lessVariable.black,
    },
    cornersSquareOptions: { type: 'square', color: _ctx.lessVariable.black },
    width: 300,
    height: 300
  }, null, 8, ["value", "dotsOptions", "cornersSquareOptions"]))
}