
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import QRCode from "../QRCode.vue";

export default defineComponent({
  props: {
    otpSetupDetails: {
      type: Object as PropType<unknown>,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
    },
  },
  emits: [],
  setup() {
    const { t } = useI18n();

    return { t, config };
  },
  components: { QRCode },
});
