import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "two-factor-authentication-setup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_AuthenticatorInstruction1 = _resolveComponent("AuthenticatorInstruction1")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_AuthenticatorInstruction2 = _resolveComponent("AuthenticatorInstruction2")!
  const _component_AuthenticatorInstruction3 = _resolveComponent("AuthenticatorInstruction3")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonCardLayout, {
      title: 
        _ctx.revoke ? _ctx.t('2FA revoked. Add new code') : _ctx.t('Set up 2FA verification')
      
    }, {
      subtitle: _withCtx(() => [
        _createVNode(_component_a_typography_paragraph, { class: "small lighter whitespace-normal" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.revoke
              ? _ctx.t(
                  "You cannot use your {merchantBrand} profile until 2FA is set up",
                  {
                    merchantBrand: _ctx.config.merchantBrand,
                  }
                )
              : _ctx.t("Need to keep your data safe")), 1)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_row, { justify: "center" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              xs: 24,
              sm: 24,
              md: 8,
              lg: 8,
              class: "instruction-container instruction-container__step1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AuthenticatorInstruction1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              xs: 24,
              sm: 24,
              md: 8,
              lg: 8,
              class: "instruction-container instruction-container__step2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AuthenticatorInstruction2, {
                  otpSetupDetails: _ctx.otpSetupDetails,
                  loading: _ctx.generate2faLoading
                }, null, 8, ["otpSetupDetails", "loading"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              xs: 24,
              sm: 24,
              md: 8,
              lg: 8,
              class: "instruction-container instruction-container__step3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AuthenticatorInstruction3, {
                  onSubmit: _ctx.handleCodeSubmit,
                  loading: _ctx.setup2faLoading,
                  disabled: _ctx.generate2faLoading,
                  ref: "otpInputRef"
                }, null, 8, ["onSubmit", "loading", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}