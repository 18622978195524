
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { openInNewTab } from "@/shared/utils/browser";
import appStoreSrc from "@/assets/app-store.png";
import googlePlaySrc from "@/assets/google-play.png";
import { config } from "@/shared/utils/config";

export default defineComponent({
  props: {},
  emits: [],
  setup() {
    const { t } = useI18n();

    return {
      t,
      appStoreSrc,
      googlePlaySrc,
      handleOpenAppStore: () => openInNewTab(config.authenticatorAppStoreURL),
      handleOpenGooglePlay: () =>
        openInNewTab(config.authenticatorGooglePlayURL),
    };
  },
  components: {},
});
