
import { defineComponent } from "vue";
import QRCodeVue3 from "qrcode-vue3";
import lessVariable from "@/less/variables.less";

export default defineComponent({
  props: {
    value: {
      type: String,
    },
  },
  setup() {
    return {
      lessVariable,
    };
  },
  components: { QRCodeVue3 },
});
