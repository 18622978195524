
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "../Forms/FormGroup.vue";
import InputOtp from "../Forms/InputOtp.vue";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const inputOtpRef = ref();

    const formState = reactive({
      code: "",
    });

    const handleSubmit = () => {
      emit("submit", formState.code);
    };

    return {
      t,
      inputOtpRef,
      formState,
      makeRequiredRule,
      handleSubmit,
      clearInput: () => {
        // just a pass-thru clearInput Method
        inputOtpRef.value?.clearInput();
      },
    };
  },
  components: { InputOtp, FormGroup },
});
