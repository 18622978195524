import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "authenticator-instruction-1" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_title, {
      level: 4,
      class: "font-normal mb-4"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 1. " + _toDisplayString(_ctx.t("Download application")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, { class: "small" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t(
          "This will allow you to confirm transfers, log in and protect your funds. Visit AppStore, Google PlayStore or Microsoft Market place to download Authenticator app."
        )), 1)
      ]),
      _: 1
    }),
    _createElementVNode("img", {
      src: _ctx.appStoreSrc,
      alt: "App Store",
      class: "block mb-2 cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOpenAppStore && _ctx.handleOpenAppStore(...args)))
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      src: _ctx.googlePlaySrc,
      alt: "Google Play",
      class: "block cursor-pointer",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleOpenGooglePlay && _ctx.handleOpenGooglePlay(...args)))
    }, null, 8, _hoisted_3)
  ]))
}