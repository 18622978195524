import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "authenticator-instruction-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_InputOtp = _resolveComponent("InputOtp")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_title, {
      level: 4,
      class: "font-normal mb-4"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 3. " + _toDisplayString(_ctx.t("Set up Authenticator")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, { class: "small" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t(
          "Type one-time-password from the app to finish setting up the Authenticator app."
        )), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_FormGroup, {
      "wrapper-col": { span: 24 },
      layout: "vertical",
      model: _ctx.formState,
      class: "text-center",
      onFinish: _ctx.handleSubmit,
      loading: _ctx.loading,
      disabled: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputOtp, {
          class: "text-center",
          name: "code",
          value: _ctx.formState.code,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.code) = $event)),
          ref: "inputOtpRef",
          onOnComplete: _ctx.handleSubmit
        }, null, 8, ["value", "onOnComplete"]),
        _createVNode(_component_a_button, {
          type: "primary",
          "html-type": "submit",
          block: "",
          loading: _ctx.loading,
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Continue")), 1)
          ]),
          _: 1
        }, 8, ["loading", "disabled"])
      ]),
      _: 1
    }, 8, ["model", "onFinish", "loading", "disabled"])
  ]))
}